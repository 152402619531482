import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import { Header, Item, Label } from "semantic-ui-react"
import kochbuch from "../images/kochbuch.png"

const IndexPage = () => (
  <Layout>
    <Header as='h1'>Portfolio</Header>

    <Item.Group unstackable>
      <Item>
        <Item.Content>
          <Item.Header>thomas.waldecker.dev</Item.Header>
          <Item.Meta>2020</Item.Meta>
          <Item.Description>
            The blog which you are visiting currently.
          </Item.Description>
          <Item.Extra>
            <a href="https://thomas.waldecker.dev">https://thomas.waldecker.dev</a><br />
            <Label>Gatsby</Label>
            <Label>React</Label>
            <Label>nginx</Label>
            <Label>Semantic UI</Label>
          </Item.Extra>
        </Item.Content>
      </Item>
      <Item>
        <Item.Content>
          <Item.Header>Cross-Plattform App for a customer</Item.Header>
          <Item.Meta>2019 - 2020</Item.Meta>
          <Item.Description>
            Built a cross-plattform app for a customer using Flutter framework.
            The app communicates over wifi using http with a embedded device.
            <br />
            In this project I was lead developer for the app and project manager
            for the embedded and app development.
          </Item.Description>
          <Item.Extra>
            <Label>Flutter</Label>
            <Label>iOS</Label>
            <Label>Android</Label>
            <Label>node.js</Label>
            <Label>Express</Label>
            <Label>embedded</Label>
          </Item.Extra>
        </Item.Content>
      </Item>
      <Item>
        <Item.Image size="tiny" src={kochbuch} />
        <Item.Content>
          <Item.Header>Kochbuch</Item.Header>
          <Item.Meta>2019 - 2020</Item.Meta>
          <Item.Description>
            My personal recipe database with generation of shopping list for needed ingredients.
          </Item.Description>
          <Item.Extra>
            <a href="https://rezepte.waldecker.dev">https://rezepte.waldecker.dev</a><br />
            <Label>Hasura</Label>
            <Label>GraphQL</Label>
            <Label>PostgreSQL</Label>
            <Label>React</Label>
            <Label>PWA</Label>
            <Label>Semantic UI</Label>
            <Label>Docker</Label>
            <Label>TypeScript</Label>
          </Item.Extra>
        </Item.Content>
      </Item>
      <Item>
        <Item.Content>
          <Item.Header>Qurdo</Item.Header>
          <Item.Meta>2016 - 2018</Item.Meta>
          <Item.Description>
            Everytime after visiting a trade-fair you bring home a big bag with flyers.
          </Item.Description>
          <Item.Extra>
            <a href="http://qurdo.com">http://qurdo.com</a><br />
            <Label>C#</Label>
            <Label>Ionic</Label>
            <Label>Angular</Label>
            <Label>Typescript</Label>
            <Label>Android</Label>
            <Label>iOS</Label>
            <Label>SQL Server</Label>
            <Label>Azure</Label>
          </Item.Extra>
        </Item.Content>
      </Item>
      <Item>
        <Item.Content>
          <Item.Header>CRM-System</Item.Header>
          <Item.Meta>2013 - 2016</Item.Meta>
          <Item.Description>
            In-house development of a customer relationship management system.
          </Item.Description>
          <Item.Extra>
            <Label>C#</Label>
            <Label>Angular.JS</Label>
            <Label>Typescript</Label>
            <Label>Bootstrap</Label>
            <Label>IIS</Label>
            <Label>SQL Server</Label>
          </Item.Extra>
        </Item.Content>
      </Item>
      <Item>
        <Item.Content>
          <Item.Header>GymZap</Item.Header>
          <Item.Description>
            Using the videostreaming technology of EveZap we were looking for different use-cases.
            GymZap was a live-streaming fitness plattform.
          </Item.Description>
          <Item.Extra>
            <Label>PHP</Label>
            <Label>Apache httpd</Label>
            <Label>Symfony</Label>
            <Label>MySQL</Label>
            <Label>ubuntu</Label>
          </Item.Extra>
        </Item.Content>
      </Item>
      <Item>
        <Item.Content>
          <Item.Header>EveZap</Item.Header>
          <Item.Meta>2009 - 2010</Item.Meta>
          <Item.Description>
            Ever wondered how the vibe is at a party location before deciding where to go?
            EveZap was a lifestreaming service for night clubs.
          </Item.Description>
          <Item.Extra>
            <Label>PHP</Label>
            <Label>Apache httpd</Label>
            <Label>Symfony</Label>
            <Label>MySQL</Label>
          </Item.Extra>
        </Item.Content>
      </Item>
      <Item>
        <Item.Content>
          <Item.Header>Elektrotechnik Waldecker</Item.Header>
          <Item.Meta>2001 - current</Item.Meta>
          <Item.Description>
            The website for the company of my dad.
          </Item.Description>
          <Item.Extra>
            <Label>HTML</Label>
            <Label>CSS</Label>
            <Label>JavaScript</Label>
          </Item.Extra>
        </Item.Content>
      </Item>
      <Item>
        <Item.Content>
          <Item.Header>Bayerische Einzelpaddler</Item.Header>
          <Item.Meta>2001 - current</Item.Meta>
          <Item.Description>
            Kayaking is my passion. The Bayerische Einzelpaddler Vereinigung is Germans biggest canoe club.
            For about 20 the website is maintained by me. Different technologies were used.
            The current incarnation is using the apostrophe content management framework on node.js.
          </Item.Description>
          <Item.Extra>
            <a href="http://einzelpaddler.de">http://einzelpaddler.de</a><br />
            <Label>node.js</Label>
            <Label>apostrophe CMS</Label>
            <Label>ubuntu</Label>
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
  </Layout>
)

export default IndexPage
